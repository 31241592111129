export const AdaptiveCollection = 'adaptive_collection';
export const BaseCollection = 'collection';
export const Billboard = 'billboard';
export const CallToAction = 'call_to_action';
export const CollectionDetails = 'collection_details';
export const CollectionsModal = 'collections_modal';
export const CollectionTabs = 'collection_tabs';
export const CollectionTabsMasthead = 'collection_tabs_masthead';
export const ConnectedLoginContainer = 'connected_login_container';
export const ContentTabs = 'content_tabs';
export const ContentWithAnchor = 'content_with_anchor';
export const DetailEntityMasthead = 'detailentity_masthead';
export const Devices = 'devices';
export const DualCtaMasthead = 'dual_cta_masthead';
export const EditorialCollection = 'editorial_collection';
export const EmailUnsubscribe = 'email_unsubscribe';
export const EpisodeCollection = 'episode_collection';
export const ExitIntentModal = 'exit_intent_modal';
export const FAQs = 'faq';
export const GridCollection = 'grid_collection';
export const Headline = 'headline';
export const HeroSliderMasthead = 'hero_slider_masthead';
export const HubNav = 'hub_nav';
export const HubTabs = 'hub_tabs';
export const JsonConfigModule = 'json_config_module';
export const ListCard = 'list_card';
export const LoginForm = 'login_form';
export const LoginModal = 'login_modal';
export const LogoWall = 'networks';
export const Masthead = 'masthead';
export const MastheadCarousel = 'masthead_carousel_container';
export const MediaContainer = 'media_container';
export const MediaWithText = 'media_with_text';
export const MicroSite = 'micro_site';
export const Modal = 'modal';
export const Navigation = 'navigation';
export const NetworksGeo = 'networks_geo';
export const NetworksSearchModal = 'zip_code_modal';
export const NetworksSearchModalWithGeo = 'zip_code_modal_geo';
export const PlanComparisonChart = 'plan_comparison_chart';
export const PlanComparisonChartWithToggle =
  'plan_comparison_chart_with_toggle';
export const PlansContainer = 'plans_container';
export const PortraitCollection = 'portrait_collection';
export const QRCodeContainer = 'qrcode_container';
export const Ribbon = 'ribbon';
export const SignupModal = 'signup_modal';
export const SpotlightTabs = 'spotlight_tabs';
export const SimpleCollection = 'simple_collection';
export const StaticHtml = 'html_module';
export const SupportedDevices = 'supported_devices';
export const TierModal = 'tier_modal';
export const Toaster = 'toaster';
export const TopHat = 'top_hat';
export const ValueProps = 'value_props';

// Welcome
export const Library = 'library';

// Experimental
export const ExpGrid = 'exp_grid';
export const ExpPlanComparisonChart = 'exp_plan_comparison_chart';
export const ExpPlanComparisonChartWithToggle =
  'exp_plan_comparison_chart_with_toggle';
export const ExpPlanComparisonChartMultiTab =
  'exp_plan_comparison_chart_multi_tab';
export const ExpPlanComparisonChartMultiTabV2 =
  'exp_plan_comparison_chart_multi_tab_v2';
export const ExpNoFeesMinimalist = 'exp_no_fees_minimalist';
export const ExpEmailCaptureMasthead = 'exp_email_capture_masthead';
export const ExpPlanBuilder = 'exp_plan_builder';
export const ExpPlanBuilderEscapeHatch = 'exp_plan_builder_hatch';
export const ExpPlanBuilderEscapeHatchV2 = 'exp_plan_builder_hatch_v2';
export const ExpPlanBuilderEscapeHatchLiveTVFirst =
  'exp_plan_builder_hatch_live_tv_first';
export const ExpLiveTVComparisonChart = 'exp_live_tv_comparison_chart';
export const ExpMastheadDualCTA = 'exp_masthead_dual_cta';
export const ExpPlanComparisonChartCategoryDropdown =
  'exp_plan_comparison_chart_category_dropdown';
export const ExpDropdownPlanComparisonChart = 'exp_dropdown_comparison_chart';
